.link {
    a {
        color:#BDC3CB;
        background:transparent;
        text-decoration:none;
        font-size:16px;
        font-weight:bold;
        display:flex;
        align-items:center;
        width:auto;

        span {
            max-width:0;
            opacity:0;
            overflow:hidden;
            transition:all ease .5s;
            white-space:nowrap;
        }

        &[aria-current] {
            color:#10459E;

            lord-icon {
                color:#10459E;
            }

            span {
                margin-left:8px;
                max-width:180px;
                opacity:1;
            }
        }

        [data-box_icon] {
            pointer-events:none;
        }

        svg,
        lord-icon {
            font-size:24px;            
        }
    }
}

// IMPRESSÃO
@media print {
    .link {
        a {
            display:none;

            &[aria-current="page"] {
                display:block;
            }
        }
    }
}